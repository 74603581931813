<template>
    <div class="grid p-jc-center p-ai-center vertical-container">
        <div class="col-12 my-5 md:col-3 lg:col-2">
            <router-link to="/">
                <img :src="logo" alt="" class="img-responsive" />
            </router-link>
        </div>
    </div>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
    <div class="grid p-jc-center my-5 p-ai-center vertical-container">
        <p v-html="settings.general.footer"></p>
    </div>
</template>
<script>
export default {
    props: ['settings'],
    data() {
        return {
        }
    },
    computed: {
        logo() {
            return (this.settings.general.logo) ? process.env.VUE_APP_ASSETS_FOLDER + '/' + this.settings.general.logo : process.env.VUE_APP_DEFAULT_LOGO;
        }
    },
}
</script>

<style scoped>
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.vertical-container {
    justify-content: center;
}
</style>